import React, { Component, Fragment } from "react"

import { withAuth0 } from "@auth0/auth0-react"
import FingerprintJS from "@fingerprintjs/fingerprintjs"
import Alert from "react-bootstrap/Alert"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { FormattedMessage } from "react-intl"
import { injectIntl } from "react-intl"
import ReactMarkdown from "react-markdown"

import Error from "./error"
import ExchangeCurrenciesShort from "./exchange_currencies_short"
import ExchangeCurrencyWarning from "./exchange_currency_warning"
import ExchangeOrderDetails2 from "./exchange_order_details_2"
import Loading from "./loading"
import Mandatory from "./mandatory"
import Space from "./space"

class ExchangeSell extends Component {
  constructor(props) {
    super(props)
    this.getRate = this.getRate.bind(this)
    this.handleClearAll = this.handleClearAll.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOrder = this.handleOrder.bind(this)
    this.recalculate = this.recalculate.bind(this)
    this.refresh = this.refresh.bind(this)
    this.refreshSellDetails = this.refreshSellDetails.bind(this)

    const user = this.props.auth0.user

    this.state = {
      isLoaded: false,
      error: null,
      rates: [],
      status: "order",
      alert: null,
      email: user != null ? user.email : "",
      amount: 0,
      currency: "BTC",
      useBelgacoinWallet: true,
      iban: "",
      beneficiary: "",
      order: null,
    }
  }

  componentDidMount() {
    this.timer = setInterval(this.refresh, 60 * 1000)
    this.refresh()
    this.refreshSellDetails()
  }

  componentWillReceiveProps(nextProps) {
    const nextUser = nextProps.auth0.user
    const user = this.props.auth0.user
    if (nextUser != user) {
      this.setState(
        {
          email: nextUser != null ? nextUser.email : "",
        },
        this.refreshSellDetails
      )
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  getRate(currencyFrom, currencyTo) {
    if (currencyFrom == currencyTo) {
      return 1
    }
    var rates = this.state.rates
    for (var i = 0; i < rates.length; i++) {
      var rate = rates[i]
      if (rate.currencyFrom == currencyFrom && rate.currencyTo == currencyTo) {
        return 1 * rate.value
      }
      if (rate.currencyFrom == currencyTo && rate.currencyTo == currencyFrom) {
        return 1 / rate.value
      }
    }
    return null
  }

  handleClearAll(e) {
    e.preventDefault()

    const { user } = this.props.auth0

    this.setState(
      {
        status: "order",
        alert: null,
        email: user != null ? user.email : "",
        amount: 0,
        currency: "BTC",
        useBelgacoinWallet: true,
        iban: "",
        beneficiary: "",
        order: null,
      },
      this.refreshSellDetails
    )
  }

  handleInputChange(e) {
    const target = e.target
    const name = target.name
    const value = target.type === "checkbox" ? target.checked : target.value

    this.setState(
      { [name]: value },
      name != "useBelgacoinWallet" ? this.recalculate : this.refreshSellDetails
    )
  }

  handleOrder(e) {
    e.preventDefault()

    if (
      (this.state.currency == "BNB" ||
        this.state.currency == "SHIB" ||
        this.state.currency == "VET" ||
        this.state.currency == "USDT" ||
        this.state.currency == "BAT" ||
        this.state.currency == "ADA" ||
        this.state.currency == "SOL" ||
        this.state.currency == "DOT" ||
        this.state.currency == "USDC" ||
        this.state.currency == "UNI" ||
        this.state.currency == "LINK" ||
        this.state.currency == "ALGO" ||
        this.state.currency == "WBTC" ||
        this.state.currency == "MATIC" ||
        this.state.currency == "XLM" ||
        this.state.currency == "ATOM" ||
        this.state.currency == "FIL" ||
        this.state.currency == "AXS") &&
      !this.state.useBelgacoinWallet
    ) {
      this.setState({
        alert:
          'This currency is only available with "Use my Belgacoin Wallet".',
      })
      return
    }

    if (this.state.useBelgacoinWallet && this.props.auth0.user == null) {
      this.setState({
        alert: 'Please login to use "Use my Belgacoin Wallet".',
      })
      return
    }

    this.setState(
      {
        status: "ordering",
        alert: null,
      },
      () => {
        FingerprintJS.load().then(fpAgent => {
          // new FingerprintJS().get(fingerprint => {
          fpAgent.get().then(fpResult => {
            const p = !this.state.useBelgacoinWallet
              ? Promise.resolve()
              : this.props.auth0.getAccessTokenSilently({
                  audience: process.env.GATSBY_AUTH0_AUDIENCE,
                  scope: process.env.GATSBY_AUTH0_SCOPE,
                })
            p.then(accessToken => {
              const url = new URL(
                !this.state.useBelgacoinWallet
                  ? "/exchange/sell"
                  : "/exchange/sell_bw",
                process.env.GATSBY_API_URL
              )
              const params = {}
              params.email = this.state.email
              params.language = this.props.intl.locale
              params.amount = this.state.amount
              params.currency = this.state.currency
              if (!this.state.useBelgacoinWallet) {
                params.iban = this.state.iban
                params.beneficiary = this.state.beneficiary
              }
              params.fingerprint = fpResult.visitorId
              // params.fingerprint = fingerprint
              Object.keys(params).forEach(key =>
                url.searchParams.append(key, params[key])
              )
              fetch(url, {
                method: "POST",
                headers: {
                  Authorization: !this.state.useBelgacoinWallet
                    ? null
                    : `Bearer ${accessToken}`,
                },
              })
                .then(response => {
                  if (response.ok) {
                    response.text().then(text => {
                      this.setState({
                        status: "ordered",
                        order: text.length ? JSON.parse(text) : null,
                      })
                    })
                  } else {
                    response.text().then(text => {
                      this.setState({
                        status: "order",
                        alert: text,
                      })
                    })
                  }
                })
                .catch(error => {
                  this.setState({
                    status: "order",
                    alert: error.message,
                  })
                })
            })
          })
        })
      }
    )
  }

  recalculate() {
    const amountStr = this.state.amount

    if (!amountStr) {
      return
    }

    const amount = Number(amountStr)

    if (!amount) {
      return
    }

    /* if (amount < 3) {
      return
    } */

    let fees = amount * 0.015
    /* if (fees < 2) {
      fees = 2
    } */

    const currency = this.state.currency

    if (!currency) {
      return
    }

    const rate = this.getRate(currency, "EUR")

    if (!rate) {
      return
    }

    let amountTo = (amount - fees) * rate
    amountTo = amountTo.toFixed(2)

    this.setState({ rate: rate, amountTo: amountTo })
  }

  refresh() {
    const url = new URL("/exchange_rates/latest", process.env.GATSBY_API_URL)
    fetch(url, { method: "GET" })
      .then(response => {
        if (response.ok) {
          response.json().then(json => {
            this.setState(
              { isLoaded: true, error: null, rates: json },
              this.recalculate
            )
          })
        } else {
          response.text().then(text => {
            this.setState({ isLoaded: true, error: text })
          })
        }
      })
      .catch(error => {
        this.setState({
          isLoaded: true,
          error: "Error: " + error.message + ".",
        })
      })
  }

  refreshSellDetails() {
    const { user } = this.props.auth0

    if (user == null) {
      this.setState({ sellDetails: null })
      return
    }

    FingerprintJS.load().then(fpAgent => {
      // new FingerprintJS().get(fingerprint => {
      fpAgent.get().then(fpResult => {
        this.props.auth0
          .getAccessTokenSilently({
            audience: process.env.GATSBY_AUTH0_AUDIENCE,
            scope: process.env.GATSBY_AUTH0_SCOPE,
          })
          .then(accessToken => {
            const url = new URL(
              "/exchange/sell_details",
              process.env.GATSBY_API_URL
            )
            const params = {}
            params.fingerprint = fpResult.visitorId
            // params.fingerprint = fingerprint
            Object.keys(params).forEach(key =>
              url.searchParams.append(key, params[key])
            )
            fetch(url, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            })
              .then(response => {
                if (response.ok) {
                  response.json().then(json => {
                    this.setState({
                      isLoaded: true,
                      error: null,
                      sellDetails: json,
                      bankVerified: json.bankVerified,
                      iban: json.iban,
                      beneficiary: json.beneficiary,
                    })
                  })
                } else {
                  response.text().then(text => {
                    this.setState({ isLoaded: true, error: text })
                  })
                }
              })
              .catch(error => {
                this.setState({
                  isLoaded: true,
                  error: "Error: " + error.message + ".",
                })
              })
          })
      })
    })
  }

  render() {
    const { intl } = this.props
    const { isLoaded, error } = this.state
    if (!isLoaded) {
      return <Loading />
    } else if (error) {
      return <Error error={error} />
    } else {
      return (
        <Row>
          <Col className="col-auto body1">
            <Card className="border-dark mb-2">
              <Card.Header className="text-white bg-dark">
                <FormattedMessage id={"Sell"} />
              </Card.Header>
              <Card.Body>
                {this.state.status == "ordered" &&
                  !this.state.useBelgacoinWallet && (
                    <Alert variant="success" className="text-justify">
                      <ReactMarkdown
                        children={intl.formatMessage(
                          { id: "Exchange.Sell.Ordered" },
                          { address: this.state.order.address10 }
                        )}
                      />
                    </Alert>
                  )}
                {this.state.status == "ordered" &&
                  this.state.useBelgacoinWallet && (
                    <Alert variant="success" className="text-justify">
                      <ReactMarkdown
                        children={intl.formatMessage({
                          id: "Exchange.Sell.OrderedBw",
                        })}
                      />
                    </Alert>
                  )}
                {this.state.alert && (
                  <Alert variant="danger">
                    <FormattedMessage id={this.state.alert} />
                  </Alert>
                )}
                <Form autoComplete="off">
                  {(this.state.status == "order" ||
                    this.state.status == "ordering" ||
                    (this.state.status == "ordered" &&
                      this.state.order == null)) && (
                    <>
                      <Form.Group>
                        <Form.Label>
                          <Mandatory>
                            <FormattedMessage id={"Email"} />
                          </Mandatory>
                        </Form.Label>
                        <Form.Control
                          name="email"
                          type="email"
                          value={this.state.email}
                          disabled={
                            this.state.status != "order" ||
                            this.props.auth0.user != null
                          }
                          onChange={this.handleInputChange}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>
                          <Mandatory>
                            <FormattedMessage id={"Amount"} />
                          </Mandatory>
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            name="amount"
                            type="number"
                            value={this.state.amount}
                            disabled={this.state.status != "order"}
                            onChange={this.handleInputChange}
                            style={{ width: "75%" }}
                          />
                          <Form.Control
                            name="currency"
                            as="select"
                            value={this.state.currency}
                            disabled={this.state.status != "order"}
                            onChange={this.handleInputChange}
                            style={{ width: "25%" }}
                          >
                            <ExchangeCurrenciesShort />
                          </Form.Control>
                          <ExchangeCurrencyWarning
                            currency={this.state.currency}
                            useBelgacoinWallet={this.state.useBelgacoinWallet}
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>
                          <FormattedMessage id={"Exchange Rate Type"} />
                        </Form.Label>
                        <Form.Control as="select" custom disabled>
                          <FormattedMessage id={"Floating"}>
                            {message => (
                              <option value="Floating">{message}</option>
                            )}
                          </FormattedMessage>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>
                          <FormattedMessage id={"Rate 1"} />
                        </Form.Label>
                        <Form.Control
                          name="rate"
                          type="number"
                          value={this.state.rate}
                          disabled
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>
                          <FormattedMessage id={"Amount 11"} />
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            name="amountTo"
                            type="number"
                            value={this.state.amountTo}
                            disabled
                          />
                          <InputGroup.Append>
                            <InputGroup.Text>EUR</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>
                          <Mandatory>
                            <FormattedMessage id={"Method of Payment"} />
                          </Mandatory>
                        </Form.Label>
                        <Form.Control as="select" custom>
                          <FormattedMessage id={"Bank Transfer (SEPA)"}>
                            {message => <option value="SEPA">{message}</option>}
                          </FormattedMessage>
                          <FormattedMessage id={"Bank Transfer (SEPA Instant)"}>
                            {message => (
                              <option value="SEPA Instant">{message}</option>
                            )}
                          </FormattedMessage>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <Form.Check
                          id="useBelgacoinWallet"
                          name="useBelgacoinWallet"
                          type="checkbox"
                          custom
                          label={intl.formatMessage({
                            id: "Use my Belgacoin Wallet",
                          })}
                          checked={this.state.useBelgacoinWallet}
                          onChange={this.handleInputChange}
                        />
                        {this.state.useBelgacoinWallet &&
                          this.props.auth0.user == null && (
                            <Form.Text className="text-muted">
                              <FormattedMessage id={"You should"} />
                              <Space />
                              <a
                                href="#"
                                onClick={() =>
                                  this.props.auth0.loginWithPopup()
                                }
                              >
                                <FormattedMessage id={"login"} />
                              </a>
                              <Space />
                              <FormattedMessage id={"to use this feature."} />
                            </Form.Text>
                          )}
                        {this.state.useBelgacoinWallet &&
                          this.props.auth0.user != null &&
                          !this.state.bankVerified && (
                            <Form.Text className="text-muted">
                              <FormattedMessage
                                id={
                                  "You must verify your bank account to use this feature."
                                }
                              />
                            </Form.Text>
                          )}
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>
                          <Mandatory>
                            <FormattedMessage id={"IBAN"} />
                          </Mandatory>
                        </Form.Label>
                        <Form.Control
                          name="iban"
                          type="text"
                          value={this.state.iban}
                          disabled={this.state.status != "order"}
                          onChange={this.handleInputChange}
                          readOnly={this.state.useBelgacoinWallet}
                        />
                        <Form.Text className="text-muted">
                          <FormattedMessage id={"IBAN hint."} />
                        </Form.Text>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>
                          <Mandatory>
                            <FormattedMessage id={"Beneficiary"} />
                          </Mandatory>
                        </Form.Label>
                        <Form.Control
                          name="beneficiary"
                          type="text"
                          value={this.state.beneficiary}
                          disabled={this.state.status != "order"}
                          onChange={this.handleInputChange}
                          readOnly={this.state.useBelgacoinWallet}
                        />
                        <Form.Text className="text-muted">
                          <FormattedMessage id={"Beneficiary hint."} />
                        </Form.Text>
                      </Form.Group>
                    </>
                  )}
                  {this.state.status == "ordered" && this.state.order && (
                    <ExchangeOrderDetails2 order={this.state.order} />
                  )}
                  {this.state.status == "order" && (
                    <>
                      <Button variant="primary" onClick={this.handleOrder}>
                        <FormattedMessage id={"Sell"} />
                      </Button>
                      <Space />
                      <Button variant="secondary" onClick={this.handleClearAll}>
                        <FormattedMessage id={"Clear All"} />
                      </Button>
                    </>
                  )}
                  {this.state.status == "ordering" && (
                    <>
                      <Button variant="primary" disabled>
                        <FormattedMessage id={"Sell"} />
                      </Button>
                      <Space />
                      <Button variant="secondary" disabled>
                        <FormattedMessage id={"Clear All"} />
                      </Button>
                    </>
                  )}
                  {this.state.status == "ordered" && (
                    <>
                      <Button variant="primary" disabled>
                        <FormattedMessage id={"Sell"} />
                      </Button>
                      <Space />
                      <Button variant="secondary" onClick={this.handleClearAll}>
                        <FormattedMessage id={"Clear All"} />
                      </Button>
                    </>
                  )}
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col className="col-auto body2">
            <Alert variant="secondary">
              <ReactMarkdown
                children={intl.formatMessage({ id: "Exchange.Sell" })}
              />
            </Alert>
          </Col>
        </Row>
      )
    }
  }
}

export default withAuth0(injectIntl(ExchangeSell))
