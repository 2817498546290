import React from "react"

const ExchangeCurrenciesShort = ({ includeEur }) => (
  <>
    {includeEur && <option value="EUR">EUR</option>}
    <option value="ADA">ADA</option>
    <option value="ALGO">ALGO</option>
    <option value="ATOM">ATOM</option>
    <option value="AXS">AXS</option>
    <option value="BAT">BAT</option>
    <option value="BCH">BCH</option>
    <option value="BNB">BNB</option>
    <option value="BTC">BTC</option>
    <option value="DOGE">DOGE</option>
    <option value="DOT">DOT</option>
    <option value="ETH">ETH</option>
    <option value="FIL">FIL</option>
    <option value="LINK">LINK</option>
    <option value="LTC">LTC</option>
    <option value="MATIC">MATIC</option>
    <option value="SHIB">SHIB</option>
    <option value="SOL">SOL</option>
    <option value="UNI">UNI</option>
    <option value="USDC">USDC</option>
    <option value="USDT">USDT</option>
    <option value="VET">VET</option>
    <option value="WBTC">WBTC</option>
    <option value="XLM">XLM</option>
    <option value="XRP">XRP</option>
  </>
)

export default ExchangeCurrenciesShort
